import { useState, useEffect } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import SnowComponent from '../Snow/Snow';


const lngs = {
    hu: { nativeName: 'HU' },
    en: { nativeName: 'EN' },
    de: { nativeName: 'DE' }
};

export default function Header() {
    const { t, i18n } = useTranslation();

    const [imageDefaultSrc, setImageDefaultSrc] = useState('images/logo.png');
    const [imageHoverSrc, setImageHoverSrc] = useState('images/logoRed.png');


    useEffect(() => {
        setImageDefaultSrc('images/logo.png');
        setImageHoverSrc('images/logoRed.png');
    }, []);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark">
                <Container >
                    <Navbar.Brand href="/#rolunk">
                        <img src={imageDefaultSrc} onMouseOver={() => setImageDefaultSrc(imageHoverSrc)} onMouseOut={() => setImageDefaultSrc('images/logo.png')} alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            {Object.keys(lngs).map((lng) => (
                                <Nav.Link key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}
                                </Nav.Link>
                            ))}
                            <Nav.Link className='redHover' eventKey={1} href="/#rolunk">
                                {t('aboutus')}
                            </Nav.Link>
                            <Nav.Link className='redHover' eventKey={2} href="/#references">
                                {t('references')}
                            </Nav.Link>
                            <Nav.Link className='redHover' eventKey={3} href="/#karrier">
                                {t('career')}
                            </Nav.Link>
                            <Nav.Link className='redHover' eventKey={4} href="/#certificates">
                                {t('certificates')}
                            </Nav.Link>
                            <Nav.Link className='redHover' eventKey={5} href="/#kapcsolat">
                                {t('contact')}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <SnowComponent />
        </>
    )
}
