import React from 'react';
import { useTranslation } from 'react-i18next';

const Certificates = () => {
    const { t } = useTranslation();
    return (
        <div id="certificates" className="w3-padding-32 w3-content w3-text-grey" style={{ marginBottom: 64 }}>
            <h2>{t('certificates')}</h2>
            <hr className="w3-opacity" />
            <img src="images/iso logo.png" alt="Iso" />
        </div>
    );
};

export default Certificates;
